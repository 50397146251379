import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMapMarkerAlt,
    faPhone,
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
    faInstagram,
    faFacebook,
    faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";

const Footer = () => {
    const handleMapLinkClick = () => {
        // Open Google Maps location
        window.open(
            "https://www.google.com/maps/place/Thakur+Cottage+Homestay/@32.0108407,77.3166635,17z/data=!4m20!1m10!3m9!1s0x39045c6826d96683:0xc26690cb19a39b55!2sThakur+Cottage+Homestay!5m2!4m1!1i2!8m2!3d32.0108362!4d77.3192384!16s%2Fg%2F11f5lybr_q!3m8!1s0x39045c6826d96683:0xc26690cb19a39b55!5m2!4m1!1i2!8m2!3d32.0108362!4d77.3192384!16s%2Fg%2F11f5lybr_q?entry=ttu",
            "_blank"
        );
    };

    const handlePhoneLinkClick = () => {
        // Open phone dialer
        window.open("tel:+918219809833");
    };

    const handleEmailLinkClick = () => {
        // Open email app
        window.open("mailto:chiefhost@forestaliving.com");
    };

    return (
        <footer className="footer">
            <div className="footer-info">
                <h2>Foresta Living</h2>
                <p>
                    Discover the charm of our inviting farmstay, nestled beside
                    the lush green forest in Parvati Valley. Immerse yourself in
                    tranquility and savor breathtaking mountain views
                </p>
            </div>
            <div className="footer-contact">
                <h3>Contact</h3>
                <ul>
                    <li>
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                        <span
                            onClick={handleMapLinkClick}
                            role="button"
                            tabIndex={0}
                        >
                            Bhuntar-Manikaran-Tosh Road, Kasol, Himachal Pradesh
                            175105{" "}
                        </span>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faPhone} />
                        <span
                            onClick={handlePhoneLinkClick}
                            role="button"
                            tabIndex={0}
                        >
                            +91 8126986123
                        </span>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <span
                            onClick={handleEmailLinkClick}
                            role="button"
                            tabIndex={0}
                        >
                            chiefhost@forestaliving.com
                        </span>
                    </li>
                </ul>
            </div>
            <div className="footer-social">
                <h3>Follow Us</h3>
                <div className="social-icons">
                    <a href="https://www.instagram.com">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://www.facebook.com">
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a href="https://www.whatsapp.com">
                        <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2023 Mountain Homestay. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
